import React from 'react'
import remark from 'remark'
import remarkHTML from 'remark-html'
import remarkParse from 'remark-parse'
import Content, { HTMLContent } from '../components/Content'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { ContainerSVGT, HomeColor, MainFont, YouthColor, YouthName} from '../components/Sampler'
import { 
  squareSVGT,
  circleSVGT,
  distressedsquareSVGT,
  distressedcircleSVGT,
  ruffledcircleSVGT,
  scallopedrectangleSVGT,
  curvedsquareSVGT,
  bookmarkSVGT,
  ybbookmarkSVGT, 
  ybcircleSVGT, 
  ybcurvedSVGT, 
  ybdistressedcircleSVGT,
  ybdistressedsquareSVGT,
  ybruffledSVGT, 
  ybscallopedSVGT, 
  ybsquareSVGT 
} from '../../static/svgtext'

export const YouthPageTemplate = ({ 
  backgroundimage, 
  infants,
  infantsinfo,
  toddlers,
  toddlersinfo,
  children,
  childreninfo,
  middleschool,
  middleschoolinfo,
  highschool,
  highschoolinfo,
  college,
  collegeinfo,
  contentComponent
}) => {
  const PageContent = contentComponent || Content
  const toHTML = value => remark()
    .use(remarkParse)
    .use(remarkHTML)
    .processSync(value)
    .toString()

  return (
    <div>
      {YouthName === 'Removed' ? 
        <div>
          <div
            className="full-width-image margin-top-0"
            style={{backgroundColor: 'white'}}
          >

            {/* Google Fonts Import */}
            <link
              href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
              rel="stylesheet"
            ></link>
            <link
              href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
              rel="stylesheet"
            ></link>
            <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>

            <div
              style={{
                display: 'flex',
                height: '150px',
                lineHeight: '1',
                justifyContent: 'space-around',
                alignItems: 'left',
                flexDirection: 'column',
                width: ''
              }}
            >
              {/* BLANK WHITE CIRCLE SVG */}
              <Link to="/" title="Home">
                <div style={{ height: '300px' }}>
                  <div style={{
                    position: 'absolute',
                    marginTop: '72px',
                    width: '100%',
                    left: '0px',
                    textAlign: 'center',
                    fontSize: '3.4em',
                    color: 'white',
                    fontFamily: MainFont,
                    textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null,
                    fontWeight: MainFont === 'Maven Pro' ? 'bold' : null
                  }}>
                    Eastville Baptist Church
                  </div>
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    height="310px" viewBox="0 0 1563.000000 1525.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                      fill={HomeColor} stroke="none">
                      <path d={ContainerSVGT} />
                    </g>
                  </svg>
                </div>
              </Link>
            </div>
          </div>
          <div>
            <h1 style={{marginLeft: '-10px', fontFamily: MainFont, fontSize: '60px', textAlign: 'center'}}>...Oops.</h1>
            <p style={{ marginTop: '20px', fontFamily: 'Poppins', fontSize: '20px', textAlign: 'center' }}>You tried to go to a page<br></br>in our site that doesn't exist.</p>
            <p style={{ marginTop: '20px', marginBottom: '50px', fontFamily: 'Poppins', fontSize: '20px', textAlign: 'center' }}>To return to our home page,<br></br>click the banner above.</p>
          </div>
        </div>
      :
      <div>
        <div
          className="full-width-image margin-top-0"
          style={{
            backgroundImage: `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
              })`,
          }}
        >

          {/* Google Fonts Import */}
          <link
            href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
            rel="stylesheet"
          ></link>
          <link
            href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
            rel="stylesheet"
          ></link>
          <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
          <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
          <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
          <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>

          {/* YOUTH IMAGE HEADING */}
          <div style={{ display: 'grid' }}>

            {/* Colored Style Container */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              height="310px" viewBox="0 0 1563.000000 1525.000000"
              preserveAspectRatio="xMidYMid meet"
              style={{
                gridRow: '1 / 1',
                gridColumn: '1 / 1',
              }}>

              <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                fill={YouthColor} stroke="none">
                <path d={ContainerSVGT} />
              </g>
            </svg>

            {/* "Youth" Text */}
            <div style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: '3.2em',
              color: '#FFFFFF',
              fontFamily: MainFont,
              fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
              letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
            }}>
              {YouthName.toString().toUpperCase()}
            </div>
          </div>
        </div>

        {/* Google Fonts Import */}
        <link
          href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
          rel="stylesheet"
        ></link>

        <div className='youth-margins'>
          
          {/* INFANTS */}
          {infants ?
            <div className='youth-category-box'>

              {/* Heading with Backing */}
              <div style={{ display: 'grid',}}>
                
                {/* Backing */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="250px" viewBox="0 0 1035.000000 267.000000"
                  preserveAspectRatio="xMidYMid meet"
                  style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>

                  <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                    fill={YouthColor} stroke="none">
                    <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null}/>
                  </g>
                </svg>

                {/* Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  width: '250px',
                  fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '2.3em' : '3em',
                  color: '#FFFFFF',
                  marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                  lineHeight: null,
                  fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  Infants
                </div>
              </div>

              <div className="youth-category-info" style={{color: YouthColor}}>
                <PageContent className="content" content={toHTML(infantsinfo)} />
              </div>
            </div>
          : null}

          {/* TODDLERS */}
          {toddlers ?
            <div className='youth-category-box'>
              
              {/* Heading with Backing */}
              <div style={{ display: 'grid', }}>

                {/* Backing */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="250px" viewBox="0 0 1035.000000 267.000000"
                  preserveAspectRatio="xMidYMid meet"
                  style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>

                  <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                    fill={YouthColor} stroke="none">
                    <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null} />
                  </g>
                </svg>

                {/* Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  width: '250px',
                  fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '2.3em' : '3em',
                  color: '#FFFFFF',
                  marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                  lineHeight: null,
                  fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  Toddlers
                </div>
              </div>

              <div className="youth-category-info" style={{color: YouthColor}}>
                <PageContent className="content" content={toHTML(toddlersinfo)}/>
              </div>
            </div>
          : null}

          {/* CHILDREN */}
          {children ?
            <div className='youth-category-box'>

              {/* Heading with Backing */}
              <div style={{ display: 'grid', }}>

                {/* Backing */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="250px" viewBox="0 0 1035.000000 267.000000"
                  preserveAspectRatio="xMidYMid meet"
                  style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>

                  <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                    fill={YouthColor} stroke="none">
                    <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null} />
                  </g>
                </svg>

                {/* Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  width: '250px',
                  fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '2.3em' : '3em',
                  color: '#FFFFFF',
                  marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                  lineHeight: null,
                  fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  Children
                </div>
              </div>

              <div className="youth-category-info" style={{color: YouthColor}}>
                <PageContent className="content" content={toHTML(childreninfo)} />
              </div>
            </div>
          : null}

          {/* MIDDLE SCHOOL */}
          {middleschool ?
            <div className='youth-category-box'>

              {/* Heading with Backing */}
              <div style={{ display: 'grid',}}>
                
                {/* Backing */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="250px" viewBox="0 0 1035.000000 267.000000"
                  preserveAspectRatio="xMidYMid meet"
                  style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>

                  <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                    fill={YouthColor} stroke="none">
                    <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null}/>
                  </g>
                </svg>

                {/* Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  width: '250px',
                  fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC' ? '2em' : '2.5em',
                  color: '#FFFFFF',
                  lineHeight: MainFont === 'Playfair Display SC' ? '.83' : '.6',
                  marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Dosis' ? '10px' : MainFont === 'Playfair Display SC' ? '3px' : null,
                  marginTop:  MainFont === 'IM Fell English' ? '3px' : null,
                  fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.1px' : null
                }}>
                  Middle School
                </div>
              </div>

              <div className="youth-category-info" style={{color: YouthColor}}>
                <PageContent className="content" content={toHTML(middleschoolinfo)} />
              </div>
            </div>
          : null}

          {/* HIGH SCHOOL */}
          {highschool ?
            <div className='youth-category-box'>

              {/* Heading with Backing */}
              <div style={{ display: 'grid',}}>
                
                {/* Backing */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="250px" viewBox="0 0 1035.000000 267.000000"
                  preserveAspectRatio="xMidYMid meet"
                  style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>

                  <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                    fill={YouthColor} stroke="none">
                    <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null}/>
                  </g>
                </svg>

                {/* Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  width: '250px',
                  fontSize: MainFont === 'IM Fell English' ? '2.5em' : MainFont === 'Maven Pro' || MainFont === 'Permanent Marker'   ? '2.3em' : MainFont === 'Playfair Display SC' ? '2em' :  MainFont === 'Dosis' ? '2.8em' : '3em',
                  color: '#FFFFFF',
                  lineHeight: '.6',
                  marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : null,
                  marginTop:  MainFont === 'IM Fell English' ? '6px' : MainFont === 'Dosis' ? '-10px' : null,
                  fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.1em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.6px' : null
                }}>
                  {MainFont === 'IM Fell English' ? <div>High <br></br>School</div> : 'High School'}
                </div>
              </div>

              <div className="youth-category-info" style={{color: YouthColor}}>
                <PageContent className="content" content={toHTML(highschoolinfo)} />
              </div>
            </div>
          : null}

          {/* COLLEGE */}
          {college ?
            <div className='youth-category-box'>

              {/* Heading with Backing */}
              <div style={{ display: 'grid', }}>

                {/* Backing */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="250px" viewBox="0 0 1035.000000 267.000000"
                  preserveAspectRatio="xMidYMid meet"
                  style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>

                  <g transform="translate(0.000000,267.000000) scale(0.100000,-0.100000)"
                    fill={YouthColor} stroke="none">
                    <path d={ContainerSVGT === circleSVGT ? ybcircleSVGT : ContainerSVGT === squareSVGT ? ybsquareSVGT : ContainerSVGT === bookmarkSVGT ? ybbookmarkSVGT : ContainerSVGT === distressedcircleSVGT ? ybdistressedcircleSVGT : ContainerSVGT === distressedsquareSVGT ? ybdistressedsquareSVGT : ContainerSVGT === ruffledcircleSVGT ? ybruffledSVGT : ContainerSVGT === scallopedrectangleSVGT ? ybscallopedSVGT : ContainerSVGT === curvedsquareSVGT ? ybcurvedSVGT : null}/>
                  </g>
                </svg>

                {/* Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  width: '250px',
                  fontSize: MainFont === 'Maven Pro' || MainFont === 'Permanent Marker' || MainFont === 'Playfair Display SC'  ? '2.3em' : '3em',
                  color: '#FFFFFF',
                  marginBottom: MainFont === 'Bahnschrift' ? '4px' : MainFont === 'Playfair Display SC' ? '3px' : MainFont === 'Dosis' ? '10px' : null,
                  lineHeight: null,
                  fontVariant: MainFont === 'IM Fell English' ? 'small-caps' : MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'all-small-caps' : null,
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  College
                </div>
              </div>

              <div className="youth-category-info" style={{color: YouthColor}}>
                <PageContent className="content" content={toHTML(collegeinfo)} />
              </div>
            </div>
          : null}
        </div>
      </div>
      }
    </div>
  )
}

YouthPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  infants: PropTypes.bool,
  infantsinfo: PropTypes.string,
  toddlers: PropTypes.bool,
  toddlersinfo: PropTypes.string,
  children: PropTypes.bool,
  childreninfo: PropTypes.string,
  middleschool: PropTypes.bool,
  middleschoolinfo: PropTypes.string,
  highschool: PropTypes.bool,
  highschoolinfo: PropTypes.string,
  college: PropTypes.bool,
  collegeinfo: PropTypes.string,
  contentComponent: PropTypes.func,
}

const YouthPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <YouthPageTemplate
        backgroundimage={frontmatter.backgroundimage}
        infants={frontmatter.infants}
        infantsinfo={frontmatter.infantsinfo}
        toddlers={frontmatter.toddlers}
        toddlersinfo={frontmatter.toddlersinfo}
        children={frontmatter.children}
        childreninfo={frontmatter.childreninfo}
        middleschool={frontmatter.middleschool}
        middleschoolinfo={frontmatter.middleschoolinfo}
        highschool={frontmatter.highschool}
        highschoolinfo={frontmatter.highschoolinfo}
        college={frontmatter.college}
        collegeinfo={frontmatter.collegeinfo}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

YouthPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default YouthPage

export const youthPageQuery = graphql`
  query YouthPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "youth-page" } }) {
      html
      frontmatter {
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        infants
        infantsinfo
        toddlers
        toddlersinfo
        children
        childreninfo
        middleschool
        middleschoolinfo
        highschool
        highschoolinfo
        college
        collegeinfo
      }
    }
  }
`
